import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus, Pencil, GripVertical, Save, Clock, Trash2, Play, Ban, ArrowLeftRight } from 'lucide-react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import FightCard from '../../components/FightCard/Fightcard';
import FightSettings from '../../components/FightSettings/FightSettings';
import PauseSettings from '../../components/PauseSettings/PauseSettings';
import Pause from '../../components/Pause/Pause';
import { useFightManager } from '../../hooks/useFightManager';
import './EditFights.css';
import { API_BASE_URL } from '../../config/api';

const EditFights = () => {
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPauseModalOpen, setIsPauseModalOpen] = useState(false);
  const [editingFight, setEditingFight] = useState(null);
  const [editingPause, setEditingPause] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [eventSponsors, setEventSponsors] = useState([]);

  const {
    items,
    setItems,
    isLoading,
    error,
    saveItems,
    eventId
  } = useFightManager();

  useEffect(() => {
    const fetchEventDetails = async () => {
      if (!eventId) return;
      
      try {
        const authResponse = await fetch(`${API_BASE_URL}/auth/check-auth.php`, {
          credentials: 'include'  
        });
        
        if (!authResponse.ok) {
          navigate('/', { replace: true });
          return;
        }
  
        const authData = await authResponse.json();
        
        if (!authData.authenticated || !authData.user) {
          navigate('/', { replace: true });
          return;
        }
  
        const eventResponse = await fetch(`${API_BASE_URL}/event/get-by-id.php?id=${eventId}`, {
          credentials: 'include'
        });
        
        if (!eventResponse.ok) {
          navigate('/', { replace: true });
          return;
        }
  
        const data = await eventResponse.json();
        const eventData = data.event;
  
        const isUserAdmin = authData.authenticated && 
          (authData.user.is_admin === "1" || authData.user.is_admin === 1 || authData.user.is_admin === true);
  
        const userId = String(authData.user.id);
        const isEventUser = Array.isArray(eventData?.assigned_users) && 
          eventData.assigned_users.some(id => String(id) === userId);
  
  
        if (!isUserAdmin && !isEventUser) {
          navigate('/', { replace: true });
          return;
        }
    
        if (eventData && eventData.sponsors) {
          setEventSponsors(eventData.sponsors);
        }
  
      } catch (error) {
        navigate('/', { replace: true });
      }
    };
   
    fetchEventDetails();
  }, [eventId, navigate]);
   
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (windowWidth < 1400) {
    return (
      <div className="edit-fight-device-warning">
        <h2>Bildschirmgröße nicht unterstützt</h2>
        <p>Bitte verwenden Sie ein Gerät mit einer Bildschirmbreite von mindestens 1400 Pixeln.</p>
      </div>
    );
  }

  if (isLoading) {
    return;
  }

  if (error) {
    return;
  }

  const handleAddFight = () => {
    setEditingFight(null);
    setIsModalOpen(true);
  };

  const handleAddPause = (index) => {
    setEditingPause({ index });
    setIsPauseModalOpen(true);
  };

  const handleEditFight = (index) => {
    const fight = items.find((item, i) => i === index && item.itemType === 'fight');
    if (fight) {
      const fightForEdit = {
        id: parseInt(fight.id),
        fighter_red_id: fight.fighter_red_id,
        fighter_blue_id: fight.fighter_blue_id,
        type: fight.type || '',
        weightClass: fight.weightClass || '',
        rounds: fight.rounds || 3,
        roundDuration: String(fight.roundDuration || '3.0'),
        championship: fight.championship || 'NONE',
        isActive: Boolean(fight.isActive),
        isCancelled: Boolean(fight.isCancelled),
        event_sponsor_index: fight.event_sponsor_index,
        index: index,
        fighterRed: {
          id: fight.fighter_red_id,
          firstName: fight.fighterRed?.firstName || '',
          lastName: fight.fighterRed?.lastName || '',
          nickname: fight.fighterRed?.nickname || '',
          team: fight.fighterRed?.team || '',
          weight: fight.fighterRed?.weight || '',
          height: fight.fighterRed?.height || '',
          age: fight.fighterRed?.age || '',
          image: fight.fighterRed?.image || null,
          imageUrl: fight.fighterRed?.imageUrl || '',
          statistics: {
            wins: Number(fight.fighterRed?.statistics?.wins) || 0,
            draws: Number(fight.fighterRed?.statistics?.draws) || 0,
            losses: Number(fight.fighterRed?.statistics?.losses) || 0
          }
        },
        fighterBlue: {
          id: fight.fighter_blue_id,
          firstName: fight.fighterBlue?.firstName || '',
          lastName: fight.fighterBlue?.lastName || '',
          nickname: fight.fighterBlue?.nickname || '',
          team: fight.fighterBlue?.team || '',
          weight: fight.fighterBlue?.weight || '',
          height: fight.fighterBlue?.height || '',
          age: fight.fighterBlue?.age || '',
          image: fight.fighterBlue?.image || null,
          imageUrl: fight.fighterBlue?.imageUrl || '',
          statistics: {
            wins: Number(fight.fighterBlue?.statistics?.wins) || 0,
            draws: Number(fight.fighterBlue?.statistics?.draws) || 0,
            losses: Number(fight.fighterBlue?.statistics?.losses) || 0
          }
        }
      };
      setEditingFight(fightForEdit);
      setIsModalOpen(true);
    }
  };

  const handleEditPause = (index) => {
    const pause = items.find((item, i) => i === index && item.itemType === 'pause');
    setEditingPause({
      ...pause,
      index,
      duration: pause.duration,
      event_sponsor_index: pause.event_sponsor_index
    });
    setIsPauseModalOpen(true);
  };

  const handleDeleteItem = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
    setHasChanges(true);
  };

  const handleToggleActive = async (index) => {
    const updatedItems = [...items];
    const currentItem = updatedItems[index];

    if (currentItem.isCancelled) {
      return;
    }

    if (!currentItem.isActive) {
      updatedItems.forEach(item => {
        if (item.itemType === 'fight') {
          item.isActive = false;
        }
      });
    }
    
    currentItem.isActive = !currentItem.isActive;
    setItems(updatedItems);
    setHasChanges(true);
  };

  const handleToggleCancelled = (index) => {
    const updatedItems = [...items];
    const currentItem = updatedItems[index];
    
    currentItem.isCancelled = !currentItem.isCancelled;
    
    if (currentItem.isCancelled && currentItem.isActive) {
      currentItem.isActive = false;
    }
    
    setItems(updatedItems);
    setHasChanges(true);
  };

  const handleSwapFighters = (index) => {
    const updatedItems = [...items];
    const fight = updatedItems[index];
    
    if (fight.itemType === 'fight') {
      const tempRedId = fight.fighter_red_id;
      fight.fighter_red_id = fight.fighter_blue_id;
      fight.fighter_blue_id = tempRedId;
      
      const tempRed = { ...fight.fighterRed };
      fight.fighterRed = { ...fight.fighterBlue };
      fight.fighterBlue = tempRed;

      setItems(updatedItems);
      setHasChanges(true);
    }
  };

  const handleSaveFight = (fightData) => {
    const updatedItems = [...items];

    const fight = {
      itemType: 'fight',
      id: editingFight?.id,
      fighter_red_id: editingFight?.fighter_red_id || null,
      fighter_blue_id: editingFight?.fighter_blue_id || null,
      type: fightData.type,
      typeId: fightData.type,
      weightClass: parseInt(fightData.weightClass),
      rounds: parseInt(fightData.rounds),
      roundDuration: parseFloat(fightData.roundDuration),
      championship: fightData.championship || 'Kein Titelkampf',
      isActive: false,
      isCancelled: false,
      event_sponsor_index: fightData.event_sponsor_index,
      
      fighterRed: {
        ...fightData.fighterRed,
        id: editingFight?.fighter_red_id || null,
        image: fightData.fighterRed.image || null,
        imageUrl: fightData.fighterRed.imageUrl || null,
        firstName: fightData.fighterRed.firstName,
        lastName: fightData.fighterRed.lastName,
        nickname: fightData.fighterRed.nickname || '',
        team: fightData.fighterRed.team,
        weight: parseFloat(fightData.fighterRed.weight),
        height: parseInt(fightData.fighterRed.height),
        age: parseInt(fightData.fighterRed.age),
        statistics: {
          wins: parseInt(fightData.fighterRed.statistics.wins),
          draws: parseInt(fightData.fighterRed.statistics.draws),
          losses: parseInt(fightData.fighterRed.statistics.losses)
        }
      },
      fighterBlue: {
        ...fightData.fighterBlue,
        id: editingFight?.fighter_blue_id || null,
        image: fightData.fighterBlue.image || null,
        imageUrl: fightData.fighterBlue.imageUrl || null,
        firstName: fightData.fighterBlue.firstName,
        lastName: fightData.fighterBlue.lastName,
        nickname: fightData.fighterBlue.nickname || '',
        team: fightData.fighterBlue.team,
        weight: parseFloat(fightData.fighterBlue.weight),
        height: parseInt(fightData.fighterBlue.height),
        age: parseInt(fightData.fighterBlue.age),
        statistics: {
          wins: parseInt(fightData.fighterBlue.statistics.wins),
          draws: parseInt(fightData.fighterBlue.statistics.draws),
          losses: parseInt(fightData.fighterBlue.statistics.losses)
        }
      }
    };

    if (editingFight !== null) {
      updatedItems[editingFight.index] = fight;
    } else {
      updatedItems.push(fight);
    }

    setItems(updatedItems);
    setIsModalOpen(false);
    setEditingFight(null);
    setHasChanges(true);
  };

  const handleSavePause = (pauseData) => {
    const updatedItems = [...items];
    const pause = {
      ...pauseData,
      itemType: 'pause',
      event_sponsor_index: pauseData.event_sponsor_index
    };

    if (editingPause !== null) {
      if (editingPause.index !== undefined && !editingPause.id) {
        updatedItems.splice(editingPause.index + 1, 0, pause);
      } else {
        const existingIndex = updatedItems.findIndex(item => item.id === editingPause.id);
        if (existingIndex !== -1) {
          updatedItems[existingIndex] = pause;
        }
      }
    } else {
      updatedItems.push(pause);
    }

    setItems(updatedItems);
    setIsPauseModalOpen(false);
    setEditingPause(null);
    setHasChanges(true);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(items);
    const [reorderedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, reorderedItem);

    setItems(reorderedItems);
    setHasChanges(true);
  };

  const handleSaveAll = async () => {
    try {
      const success = await saveItems(items);
      if (success) {
        setHasChanges(false);
      }
    } catch (error) {
      console.error('Error saving fights:', error);
    }
  };

  const shouldShowPauseButton = (index) => {
    if (index === items.length - 1) return false;
    const currentItem = items[index];
    const nextItem = items[index + 1];
    return currentItem?.itemType === 'fight' && nextItem?.itemType === 'fight';
  };

  return (
    <div className="edit-fight-wrapper">
      <div className="edit-fight-container">
        <div className="edit-fight-header">
          <h1>Kämpfe bearbeiten</h1>
          <div className="edit-fight-actions">
            <button className="edit-fight-add-button" onClick={handleAddFight}>
              <Plus size={20} />
              Kampf hinzufügen
            </button>
            <button
              className="edit-fight-save-button"
              onClick={handleSaveAll}
              disabled={!hasChanges}
            >
              <Save size={20} />
              Speichern
            </button>
          </div>
        </div>

        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="items">
            {(provided) => (
              <div
                className="edit-fight-cards"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {items.map((item, index) => (
                  <React.Fragment key={`item-${index}`}>
                    <Draggable
                      draggableId={`item-${index}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className={`edit-fight-card-wrapper ${snapshot.isDragging ? 'dragging' : ''}`}
                        >
                          <div
                            className="edit-fight-drag-handle"
                            {...provided.dragHandleProps}
                          >
                            <GripVertical size={20} />
                          </div>
                          {item.itemType === 'fight' ? (
                            <>
                              <button
                                className="edit-fight-edit-button"
                                onClick={() => handleEditFight(index)}
                                title="Kampf bearbeiten"
                              >
                                <Pencil size={18} />
                              </button>
                              <button
                                className="edit-fight-delete-button"
                                onClick={() => handleDeleteItem(index)}
                                title="Kampf löschen"
                              >
                                <Trash2 size={18} />
                              </button>
                              <button
                                className={`edit-fight-status-button ${
                                  item.isActive ? 'active' : ''
                                }`}
                                onClick={() => handleToggleActive(index)}
                                disabled={item.isCancelled}
                                title={
                                  item.isCancelled
                                    ? 'Abgesagte Kämpfe können nicht aktiviert werden'
                                    : item.isActive
                                    ? 'Kampf deaktivieren'
                                    : 'Kampf aktivieren'
                                }
                              >
                                <Play size={18} />
                              </button>
                              <button
                                className={`edit-fight-status-button ${
                                  item.isCancelled ? 'cancelled' : ''
                                }`}
                                onClick={() => handleToggleCancelled(index)}
                                title={
                                  item.isCancelled
                                    ? 'Kampfabsage aufheben'
                                    : 'Kampf absagen'
                                }
                              >
                                <Ban size={18} />
                              </button>
                              <button
                                className="edit-fight-status-button swap-fighters"
                                onClick={() => handleSwapFighters(index)}
                                title="Kämpfer tauschen"
                              >
                                <ArrowLeftRight size={18} />
                              </button>
                              <FightCard
                                fighterRed={{
                                  id: item.fighter_red_id,
                                  ...item.fighterRed
                                }}
                                fighterBlue={{
                                  id: item.fighter_blue_id,
                                  ...item.fighterBlue
                                }}
                                fight={{
                                  type: item.type,
                                  weightClass: item.weightClass,
                                  rounds: item.rounds,
                                  roundDuration: item.roundDuration,
                                  championship: item.championship,
                                  isActive: item.isActive,
                                  isCancelled: item.isCancelled,
                                  event_sponsor_index: item.event_sponsor_index
                                }}
                                eventSponsors={eventSponsors}
                              />
                            </>
                          ) : (
                            <>
                              <button
                                className="edit-fight-edit-button"
                                onClick={() => handleEditPause(index)}
                                title="Pause bearbeiten"
                              >
                                <Pencil size={18} />
                              </button>
                              <button
                                className="edit-fight-delete-button"
                                onClick={() => handleDeleteItem(index)}
                                title="Pause löschen"
                              >
                                <Trash2 size={18} />
                              </button>
                              <Pause
                                duration={item.duration}
                                event_sponsor_index={item.event_sponsor_index}
                                eventSponsors={eventSponsors}
                              />
                            </>
                          )}
                        </div>
                      )}
                    </Draggable>
                    {shouldShowPauseButton(index) && (
                      <button
                        className="add-pause-button"
                        onClick={() => handleAddPause(index)}
                      >
                        <Clock size={16} />
                        Pause hinzufügen
                      </button>
                    )}
                  </React.Fragment>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      {isModalOpen && (
        <FightSettings
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setEditingFight(null);
          }}
          onSave={handleSaveFight}
          initialData={editingFight}
          eventSponsors={eventSponsors}
        />
      )}

      {isPauseModalOpen && (
        <PauseSettings
          isOpen={isPauseModalOpen}
          onClose={() => {
            setIsPauseModalOpen(false);
            setEditingPause(null);
          }}
          onSave={handleSavePause}
          initialData={editingPause?.duration ? editingPause : null}
          eventSponsors={eventSponsors}
        />
      )}
    </div>
  );
};

export default EditFights;