import React, { useState } from 'react';
import { Activity, Trophy } from 'lucide-react';
import { API_BASE_URL } from '../../config/api';
import './Fightcard.css';

import fighterRedImage from '../../assets/fighterRed.jpg';
import fighterBlueImage from '../../assets/fighterBlue.jpg';

const FightCard = ({ fighterRed, fighterBlue, fight, eventSponsors }) => {
   const [isSelected, setIsSelected] = useState(false);

   const getImageSrc = (fighter, side) => {
        if (fighter.image instanceof File) {
            return URL.createObjectURL(fighter.image);
        }
        
        if (fighter.imageUrl) {
            if (!fighter.imageUrl.startsWith('http')) {
                return `${API_BASE_URL}/uploads/${fighter.imageUrl}`;
            }
            return fighter.imageUrl;
        }
        
        return side === 'red' ? fighterRedImage : fighterBlueImage;
    };

    const getFightTypeAbbreviation = (type) => {
        const types = {
            'Kickboxen': 'KBX',
            'Boxen': 'BXN',
            'Mixed Martial Arts': 'MMA',
            'K1': 'K1'
        };
        return types[type] || type;
    };

   const FightInfo = () => {
       const sponsorData = fight.event_sponsor_index !== null && eventSponsors ? 
         eventSponsors[fight.event_sponsor_index] : null;

       const sponsorImage = sponsorData?.image_url ? 
         `${API_BASE_URL}/uploads/${sponsorData.image_url}` : 
         sponsorData?.image;

       return (
           <div className="fightcard-fight-info">
               <div className="fightcard-championship-banner">
                   <div className="fightcard-type-weight">
                       {getFightTypeAbbreviation(fight.type)}
                       <span className="fightcard-separator">|</span>
                       {fight.weightClass}kg
                       <span className="fightcard-separator">|</span>
                       {fight.rounds}x{fight.roundDuration}min
                   </div>
               </div>
               
               {sponsorData && (
                   <a 
                       href={sponsorData.website_url}
                       target="_blank" 
                       rel="noopener noreferrer"
                       className="fightcard-sponsor-link"
                       onClick={(e) => e.stopPropagation()} 
                   >
                       <img 
                           src={sponsorImage}
                           alt={sponsorData.name || 'Sponsor'}
                           className="fightcard-sponsor-image"
                       />
                   </a>
               )}

               {fight.championship !== 'NONE' && fight.championship !== 'Kein Titelkampf' && (
                   <div className="fightcard-championship-type">
                       {fight.championship}
                   </div>
               )}
           </div>
       );
   };

   const FightTitle = () => (
       <div className="fightcard-expanded-title">
           <div className="fightcard-expanded-title-content">
               {fight.type} | {fight.weightClass}kg | {fight.rounds}x{fight.roundDuration}min
               {fight.championship !== 'NONE' && fight.championship !== 'Kein Titelkampf' && (
                   <span className="fightcard-expanded-title-championship">
                       | {fight.championship}
                   </span>
               )}
           </div>
       </div>
   );

   const FighterInfo = ({ fighter, side }) => {
       return (
           <div className="fightcard-fighter-container">
               <div className={`fightcard-fighter-image-wrapper fightcard-${side}-side`}>
                   <img 
                       src={getImageSrc(fighter, side)}
                       alt={`${fighter.firstName} ${fighter.lastName}`} 
                       className="fightcard-fighter-image"
                   />
               </div>
               <div className="fightcard-name-banner">
                   <span className="fightcard-fighter-fullname">
                       {fighter.firstName} {fighter.lastName}
                   </span>
               </div>
           </div>
       );
   };  

   const FighterDetailInfo = ({ fighter, side }) => {
       return (
           <div className={`fighter-detail-container fighter-detail-${side}`}>
               <div className="fighter-profile">
                   <div className={`fighter-profile-image fighter-profile-image-${side}`}>
                       <img 
                           src={getImageSrc(fighter, side)}
                           alt={`${fighter.firstName} ${fighter.lastName}`}
                       />
                   </div>
                   <div className="fighter-profile-info">
                       <h2 className="fighter-detail-name">
                           {fighter.firstName}{' '}
                           <span className="fighter-lastname">{fighter.lastName}</span>
                       </h2>
                       {fighter.nickname && (
                           <div className="fighter-nickname">"{fighter.nickname}"</div>
                       )}
                       <div className="fighter-team">
                           {fighter.team}
                       </div>
                   </div>
               </div>

               <div className="fighter-stats-container">
                   <div className="fighter-basic-stats">
                       <div className="stat-item">
                           <span className="stat-value">{fighter.weight}kg</span>
                           <span className="stat-label">GEWICHT</span>
                       </div>
                       <div className="stat-divider" />
                       <div className="stat-item">
                           <span className="stat-value">{fighter.height}cm</span>
                           <span className="stat-label">GRÖßE</span>
                       </div>
                       <div className="stat-divider" />
                       <div className="stat-item">
                           <span className="stat-value">{fighter.age}</span>
                           <span className="stat-label">ALTER</span>
                       </div>
                   </div>

                   <div className="fighter-record">
                       <div className="record-item wins">
                           <span className="record-value">{fighter.statistics.wins}</span>
                           <span className="record-label">SIEGE</span>
                       </div>
                       <div className="record-item draws">
                           <span className="record-value">{fighter.statistics.draws}</span>
                           <span className="record-label">UNENT.</span>
                       </div>
                       <div className="record-item losses">
                           <span className="record-value">{fighter.statistics.losses}</span>
                           <span className="record-label">NIEDERL.</span>
                       </div>
                   </div>
               </div>

               {fighter.titles && fighter.titles.length > 0 && (
                   <div className="fighter-titles">
                       <div className="titles-header">
                           <Trophy size={16} />
                           <span>ERFOLGE</span>
                       </div>
                       <div className="titles-list">
                           {fighter.titles.map((title, index) => (
                               <div key={index} className="title-item">
                                   {title}
                               </div>
                           ))}
                       </div>
                   </div>
               )}
           </div>
       );
   };

   return (
       <div
           className={`fightcard-container ${isSelected ? 'expanded' : ''} ${fight.isActive ? 'active' : ''}`}
           onClick={() => setIsSelected(!isSelected)}
           style={{ width: '100%', marginBottom: 'var(--spacing-lg)' }}
       >
           {isSelected ? (
               <div className="fightcard-expanded-content">
                   <FightTitle />
                   <div className="fightcard-expanded-inner">
                       <FighterDetailInfo fighter={fighterBlue} side="blue" />
                       <div className="fighter-detail-divider" />
                       <FighterDetailInfo fighter={fighterRed} side="red" />
                   </div>
               </div>
           ) : (
               <div className={`fightcard-content ${fight.isCancelled ? 'fightcard-cancelled' : ''}`}>
                   {fight.isActive && (
                       <div className="fightcard-active-indicator">
                           <div className="fightcard-active-dot" />
                           <Activity size={14} />
                           Live
                       </div>
                   )}
                   <FighterInfo fighter={fighterBlue} side="blue" />
                   <FightInfo />
                   <FighterInfo fighter={fighterRed} side="red" />
               </div>
           )}
       </div>
   );
};

export default FightCard;