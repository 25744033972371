import React, { useState } from 'react';
import { Star } from 'lucide-react';
import { API_BASE_URL } from '../../config/api';
import './FightSponsor.css';

const FightSponsor = ({ sponsor, index, isSelected, onClick }) => {
  const [showUrl, setShowUrl] = useState(false);
  
  const imageUrl = sponsor.image_url ? 
    `${API_BASE_URL}/uploads/${sponsor.image_url}` : 
    sponsor.image;

  return (
    <div 
      className={`fightsponsor-card ${isSelected ? 'fightsponsor-selected' : ''}`}
      onClick={() => onClick(index)}
      onMouseEnter={() => setShowUrl(true)}
      onMouseLeave={() => setShowUrl(false)}
    >
      <div className="fightsponsor-image-wrapper">
        <img
          src={imageUrl}
          alt="Sponsor"
          className="fightsponsor-image"
        />
      </div>
      {sponsor.mainSponsor && (
        <div className="fightsponsor-badge">
          <Star size={14} />
          Hauptsponsor
        </div>
      )}
      {showUrl && (
        <div className="fightsponsor-url-overlay">
          <span className="fightsponsor-url">{sponsor.website_url}</span>
        </div>
      )}
    </div>
  );
};

export default FightSponsor;