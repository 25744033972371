import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { Trash2, Shield, ShieldOff, UserPlus } from 'lucide-react';
import { Link } from 'react-router-dom';
import './UserList.css';
import { API_BASE_URL } from '../../config/api';

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();
  const isAdmin = user?.is_admin == 1;

  const convertToBool = (value) => {
    if (typeof value === 'boolean') return value;
    if (typeof value === 'string') {
      return value === '1';
    }
    return !!value;
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/user/get-users.php`, {
          credentials: 'include'
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch users');
        }

        const data = await response.json();
        const usersWithConvertedBools = (data.users || []).map(user => ({
          ...user,
          is_admin: convertToBool(user.is_admin)
        }));
        setUsers(usersWithConvertedBools);
      } catch (err) {
        setError('Failed to load users. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    if (isAdmin) {
      fetchUsers();
    }
  }, [isAdmin]);

  const handleDelete = async (userId, e) => {
    e.preventDefault();
    e.stopPropagation();

    if (userId === user.id) {
      setError('You cannot delete your own account');
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/user/delete.php?id=${userId}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to delete user');
      }

      const data = await response.json();
      if (data.message === 'Benutzer erfolgreich gelöscht') {
        setUsers(users.filter(user => user.id !== userId));
      } else {
        throw new Error('Unexpected response from server');
      }
    } catch (err) {
      console.error('Delete error:', err);
      setError(err.message || 'Failed to delete user. Please try again.');
    }
  };

  if (!isAdmin) {
    return (
      <div className="user-list-error">
        You do not have permission to view this page.
      </div>
    );
  }

  if (loading) {
    return <div className="user-list-loading">Loading users...</div>;
  }

  if (error) {
    return <div className="user-list-error">{error}</div>;
  }

  return (
    <div className="user-list-page">
      <div className="user-list-container">
        <div className="user-list-header">
          <div className="user-list-header-content">
            <h1 className="user-list-title">Nutzer</h1>
            <Link to="/nutzer-anlegen" className="create-user-button">
              <UserPlus size={18} />
              <span>Nutzer anlegen</span>
            </Link>
          </div>
        </div>
        
        <div className="user-list-items">
          {users.map(userItem => (
            <div key={userItem.id} className="user-list-item">
              <div className="user-list-item-content">
                <div className="user-list-item-main">
                  <div className="user-list-item-info">
                    <h2 className="user-list-item-username">{userItem.username}</h2>
                    <span className="user-list-item-email">{userItem.email}</span>
                  </div>
                  <div className="user-list-item-role">
                    {userItem.is_admin ? (
                      <Shield size={18} className="user-list-admin-icon" />
                    ) : (
                      <ShieldOff size={18} className="user-list-user-icon" />
                    )}
                  </div>
                </div>
              </div>

              <button
                onClick={(e) => handleDelete(userItem.id, e)}
                className="user-list-delete-btn"
                aria-label="Delete user"
                disabled={userItem.id === user.id}
              >
                <Trash2 size={18} />
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UserList;