import React from 'react';
import './Impressum.css';

const Impressum = () => {
  return (
    <div className="impressum-container">
      <h1>Impressum</h1>
      <section className="impressum-section">
        <h2>Angaben gemäß § 5 TMG</h2>
        <p>Musterfirma GmbH<br />
        Musterstraße 123<br />
        12345 Musterstadt</p>
        
        <h3>Vertreten durch</h3>
        <p>Max Mustermann</p>
        
        <h3>Kontakt</h3>
        <p>Telefon: +49 (0) 123 44 55 66<br />
        E-Mail: info@musterfirma.de</p>
        
        <h3>Registereintrag</h3>
        <p>Handelsregister: HRB 12345<br />
        Registergericht: Amtsgericht Musterstadt</p>
        
        <h3>Umsatzsteuer-ID</h3>
        <p>Umsatzsteuer-Identifikationsnummer gemäß §27a UStG: DE 123456789</p>
      </section>
    </div>
  );
};

export default Impressum;