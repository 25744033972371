import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import logo from '../../assets/logo.png';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-gradient" />
      <div className="footer-content">
        <div className="footer-top">
          <div className="footer-logo-section">
            <Link to="/">
              <img src={logo} alt="Logo" className="footer-logo-image" />
            </Link>
          </div>
          
          <div className="footer-links-section">
            <div className="links-group">
              <Link to="/impressum" className="footer-link">Impressum</Link>
              <Link to="/datenschutz" className="footer-link">Datenschutz</Link>
            </div>
          </div>
        </div>
        
        <div className="footer-bottom">
          <div className="footer-copyright">
            © {currentYear} All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;