import React, { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import './UserAccount.css';
import { API_BASE_URL } from '../../config/api';

const UserAccount = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState({ type: '', content: '' });
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage({ type: '', content: '' });

    if (newPassword !== confirmPassword) {
      setMessage({ type: 'error', content: 'Die neuen Passwörter stimmen nicht überein' });
      return;
    }

    if (newPassword.length < 8) {
      setMessage({ type: 'error', content: 'Das neue Passwort muss mindestens 8 Zeichen lang sein' });
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch(`${API_BASE_URL}/auth/change-password.php`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          currentPassword,
          newPassword,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage({ type: 'success', content: data.message });
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
      } else {
        setMessage({ type: 'error', content: data.message });
      }
    } catch (error) {
      setMessage({ type: 'error', content: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="user-account">
      <div className="user-account-container">
        <h1 className="user-account-title">Benutzerkonto</h1>
        
        <div className="user-info">
          <p className="user-info-text">
            Angemeldet als: <span className="user-info-value">{user?.username}</span>
          </p>
        </div>

        <form onSubmit={handleSubmit} className="password-change-form">
          <h2 className="form-section-title">Passwort ändern</h2>
          
          <div className="form-field">
            <label htmlFor="currentPassword">Aktuelles Passwort</label>
            <input
              type="password"
              id="currentPassword"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              required
            />
          </div>

          <div className="form-field">
            <label htmlFor="newPassword">Neues Passwort</label>
            <input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>

          <div className="form-field">
            <label htmlFor="confirmPassword">Neues Passwort bestätigen</label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>

          {message.content && (
            <div className={`message ${message.type}`}>
              {message.content}
            </div>
          )}

          <button 
            type="submit" 
            className="submit-button"
            disabled={isLoading}
          >
            {isLoading ? 'Wird geändert...' : 'Passwort ändern'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default UserAccount;