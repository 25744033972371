import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import Cropper from 'react-cropper';
import { X } from 'lucide-react';
import { API_BASE_URL } from '../../config/api';
import 'cropperjs/dist/cropper.css';
import './ImageDropzone.css';

const ImageDropzone = ({
  onImageCropped,
  imageFile = null,
  previewUrl = null,
  aspectRatio = null,
  label = "Klicken oder Bild hierher ziehen"
}) => {
  const [image, setImage] = useState(null);
  const [cropper, setCropper] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const [localPreviewUrl, setLocalPreviewUrl] = useState(null);
  const objectUrls = useRef([]);

  const getFullImageUrl = (url) => {
    if (!url) return null;
    if (url.startsWith('http') || url.startsWith('blob:') || url.startsWith('/')) {
      return url;
    }
    return `${API_BASE_URL}/uploads/${url}`;
  };

  useEffect(() => {
    if (imageFile) {
      const url = URL.createObjectURL(imageFile);
      setLocalPreviewUrl(url);
      objectUrls.current.push(url);
    } else if (previewUrl) {
      const fullUrl = getFullImageUrl(previewUrl);
      setLocalPreviewUrl(fullUrl);
    } else {
      setLocalPreviewUrl(null);
    }

    return () => {
      objectUrls.current.forEach((url) => {
        URL.revokeObjectURL(url);
      });
      objectUrls.current = [];
    };
  }, [imageFile, previewUrl]);

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles?.[0]) {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
        setShowCropper(true);
      };
      reader.readAsDataURL(file);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.gif', '.webp']
    },
    multiple: false
  });

  const handleCropComplete = () => {
    if (cropper) {
      cropper.getCroppedCanvas({
        width: 1024,
        height: 1024,
        imageSmoothingQuality: 'high',
      }).toBlob((blob) => {
        const croppedFile = new File([blob], 'cropped-image.jpg', {
          type: 'image/jpeg',
          lastModified: new Date().getTime()
        });
        const newPreviewUrl = URL.createObjectURL(blob);

        if (localPreviewUrl && localPreviewUrl.startsWith('blob:')) {
          URL.revokeObjectURL(localPreviewUrl);
        }

        setLocalPreviewUrl(newPreviewUrl);
        objectUrls.current.push(newPreviewUrl);
        onImageCropped(croppedFile);
      }, 'image/jpeg', 0.95);
      setShowCropper(false);
      setImage(null);
    }
  };

  const handleCancel = () => {
    setImage(null);
    setShowCropper(false);
  };

  return (
    <div className="image-dropzone">
      {showCropper && image ? (
        <div className="cropper-wrapper">
          <button
            type="button"
            onClick={handleCancel}
            className="close-button"
          >
            <X size={20} />
          </button>
          <div className="cropper-container">
            <Cropper
              src={image}
              style={{ height: 400, width: '100%' }}
              aspectRatio={aspectRatio}
              guides={true}
              viewMode={2}
              background={false}
              autoCropArea={1}
              onInitialized={(instance) => setCropper(instance)}
            />
          </div>
          <div className="cropper-actions">
            <button
              type="button"
              onClick={handleCropComplete}
              className="crop-button crop-button-primary"
            >
              Bild zuschneiden
            </button>
          </div>
        </div>
      ) : (
        <div {...getRootProps()} className="dropzone">
          <input {...getInputProps()} />
          {localPreviewUrl ? (
            <div className="preview-container">
              <img
                src={localPreviewUrl}
                alt="Vorschau"
                className="image-preview"
                onError={(e) => {
                  console.error('Error loading image from URL:', localPreviewUrl);
                  e.target.style.display = 'none';
                }}
              />
              <p>Klicken oder neues Bild hierher ziehen zum Ändern</p>
            </div>
          ) : (
            <p>{label}</p>
          )}
        </div>
      )}
    </div>
  );
};

export default ImageDropzone;