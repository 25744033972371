import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import './styles/design.css';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import { AuthProvider } from './context/AuthContext';
import CreateUser from './pages/CreateUser/CreateUser';
import Event from './pages/Event/Event';
import Events from './pages/Events/Events';
import EditFights from './pages/EditFights/EditFights';
import UserAccount from './pages/UserAccount/UserAccount';
import UserList from './pages/UserList/UserList';
import ResetPassword from './components/ResetPassword/ResetPassword';
import ProtectedRoute from './components/ProtectedRoutes/ProtectedRoute';
import Countdown from './pages/Countdown/Countdown';
import Fight from './pages/Fight/Fight';
import Impressum from './pages/Impressum/Impressum';
import Datenschutz from './pages/Datenschutz/Datenschutz';
import './App.css'

const MainLayout = () => {
 const location = useLocation();
 const isHomePage = location.pathname === '/';
 const isCountdownPage = location.pathname.match(/^\/event\/\d+$/);
 const isFightPage = location.pathname.match(/^\/event\/\d+\/fights$/);
 const isImpressum = location.pathname === '/impressum';
 const isDatenschutz = location.pathname === '/datenschutz';
 const hideHeader = isCountdownPage || isFightPage || isImpressum || isDatenschutz;

 return (
   <>
     {!hideHeader && <Header />}
     <main className="App-main">
       <Routes>
         <Route path="/" element={null} />
         <Route path="/event/:eventId" element={<Countdown />} />
         <Route path="/event/:eventId/fights" element={<Fight />} />
         <Route 
           path="/nutzer-anlegen" 
           element={
             <ProtectedRoute adminOnly>
               <CreateUser />
             </ProtectedRoute>
           } 
         />
         <Route 
           path="/event-anlegen" 
           element={
             <ProtectedRoute adminOnly>
               <Event />
             </ProtectedRoute>
           } 
         />
         <Route 
           path="/event/bearbeiten/:eventId" 
           element={
             <ProtectedRoute>
               <Event />
             </ProtectedRoute>
           } 
         />
         <Route 
           path="/events" 
           element={
             <ProtectedRoute>
               <Events />
             </ProtectedRoute>
           } 
         />
         <Route 
           path="/benutzerkonto" 
           element={
             <ProtectedRoute>
               <UserAccount />
             </ProtectedRoute>
           } 
         />
         <Route 
           path="/benutzerverwaltung" 
           element={
             <ProtectedRoute adminOnly>
               <UserList />
             </ProtectedRoute>
           } 
         />
         <Route 
           path="/event/bearbeiten/:eventId/fights" 
           element={
             <ProtectedRoute>
               <EditFights />
             </ProtectedRoute>
           } 
         />
         <Route path="/reset/*" element={<ResetPassword />} />
         <Route path="/impressum" element={<Impressum />} />
         <Route path="/datenschutz" element={<Datenschutz />} />
         <Route path="*" element={<Navigate to="/" replace />} />
       </Routes>
     </main>
     {!isHomePage && <Footer />}
   </>
 );
};

function App() {
 return (
   <Router>
     <AuthProvider>
       <div className="App">
         <MainLayout />
       </div>
     </AuthProvider>
   </Router>
 );
}

export default App;