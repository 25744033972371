import React from 'react';
import { Clock } from 'lucide-react';
import { API_BASE_URL } from '../../config/api';
import './Pause.css';

const Pause = ({ duration, event_sponsor_index, eventSponsors }) => {
  const minutes = Math.floor(duration / 60);
  const seconds = duration % 60;

  const formattedTime = `${minutes}:${seconds.toString().padStart(2, '0')}`;

  const sponsorData = event_sponsor_index !== null && eventSponsors ? 
    eventSponsors[event_sponsor_index] : null;

  const sponsorImage = sponsorData?.image_url ? 
    `${API_BASE_URL}/uploads/${sponsorData.image_url}` : 
    sponsorData?.image;

  return (
    <div className="pause-container">
      <div className="pause-content">
        <div className="pause-time-section">
          <Clock size={24} className="pause-clock-icon" />
          <div className="pause-duration">
            <div className="pause-duration-display">
              <span className="pause-duration-value">{formattedTime}</span>
              <span className="pause-duration-unit">MIN</span>
            </div>
            <span className="pause-duration-label">PAUSE</span>
          </div>
        </div>
        
        {sponsorData && (
          <a 
            href={sponsorData.website_url}
            target="_blank"
            rel="noopener noreferrer"
            className="pause-sponsor-link"
          >
            <img 
              src={sponsorImage}
              alt={sponsorData.name || 'Sponsor'}
              className="pause-sponsor-image"
            />
          </a>
        )}
      </div>
    </div>
  );
};

export default Pause;