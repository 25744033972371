import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { API_BASE_URL } from '../../config/api';
import './ResetPassword.css';

const ResetPassword = () => {
  const [status, setStatus] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const token = location.pathname.split('token=')[1];

  useEffect(() => {
    const handleReset = async () => {
      if (!token) {
        setError('Kein gültiger Token gefunden');
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(`${API_BASE_URL}/auth/reset-password.php`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
        });

        const data = await response.json();

        if (response.ok) {
          setStatus(data.message);
          setTimeout(() => {
            navigate('/');
          }, 3000);
        } else {
          setError(data.message || 'Ein Fehler ist aufgetreten');
        }
      } catch (err) {
        setError('Es gab einen Fehler bei der Verbindung zum Server');
      } finally {
        setLoading(false);
      }
    };

    handleReset();
  }, [token, navigate]);

  return (
    <div className="reset-password">
      <div className="reset-password-container">
        <div className="reset-password-card">
          <div className="reset-password-header">
            <h1>Passwort zurücksetzen</h1>
          </div>
          
          {error && (
            <div className="reset-password-message error">
              <span className="reset-password-message-icon">⚠️</span>
              <span className="reset-password-message-text">{error}</span>
            </div>
          )}
          
          {status && (
            <div className="reset-password-message success">
              <span className="reset-password-message-icon">✓</span>
              <div className="reset-password-message-content">
                <span className="reset-password-message-text">{status}</span>
                <p className="reset-password-redirect-text">Sie werden in Kürze zur Startseite weitergeleitet...</p>
              </div>
            </div>
          )}

          {loading && (
            <div className="reset-password-loading">
              <div className="reset-password-spinner"></div>
              <span className="reset-password-loading-text">Ihr Passwort wird zurückgesetzt...</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;