import React from 'react';
import './Datenschutz.css';

const Datenschutz = () => {
  return (
    <div className="datenschutz-container">
      <h1>Datenschutzerklärung</h1>
      <section className="datenschutz-section">
        <h2>1. Datenschutz auf einen Blick</h2>
        <p>Wir freuen uns über Ihr Interesse an unserem Unternehmen. Der Schutz Ihrer personenbezogenen Daten ist uns ein wichtiges Anliegen.</p>
        
        <h3>Allgemeine Hinweise</h3>
        <p>Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie unsere Website besuchen.</p>
        
        <h3>Datenerfassung auf unserer Website</h3>
        <p>Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum dieser Website entnehmen.</p>
        
        <h2>2. Hosting und Content Delivery Networks</h2>
        <p>Unser Hoster erhebt in sog. Logfiles folgende Daten, die Ihr Browser übermittelt: IP-Adresse, die Adresse der vorher besuchten Website (Referer Anfrage-Header), Datum und Uhrzeit der Anfrage, Zeitzonendifferenz zur GMT, Inhalt der Anforderung, HTTP-Statuscode, übertragene Datenmenge, Website, von der die Anforderung kommt und Informationen zu Browser und Betriebssystem.</p>
      </section>
    </div>
  );
};

export default Datenschutz;