import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import './Header.css';
import LoginModal from '../LoginModal/LoginModal';
import logo from '../../assets/logo.png';

const Header = () => {
  const { isAuthenticated, user, logout } = useAuth();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();
  const isAdmin = user?.is_admin == 1;
  
  const navItems = [
    { 
      id: 'events', 
      label: 'Events', 
      href: '/events',
      requiresAuth: true,
      requiresAdmin: false
    },
    { 
      id: 'userList', 
      label: 'Benutzerverwaltung', 
      href: '/benutzerverwaltung',
      requiresAuth: true,
      requiresAdmin: true
    },
    { 
      id: 'userAccount', 
      label: 'Benutzerkonto', 
      href: '/benutzerkonto',
      requiresAuth: true,
      requiresAdmin: false
    }
  ];

  const handleLogout = async () => {
    await logout();
    setIsMobileMenuOpen(false);
  };

  const filteredNavItems = navItems.filter(item => {
    if (!isAuthenticated && item.requiresAuth) return false;
    if (item.requiresAdmin && !isAdmin) return false;
    return true;
  });

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleNavClick = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <>
      <header className="header">
        <div className="header-backdrop" />
        <div className="header-glow" />
        <div className="header-noise" />
        <div className="header-container">
          <div className="header-logo">
            <Link to="/">
              <img src={logo} alt="Logo" className="logo-image" />
            </Link>
          </div>
          
          {isAuthenticated && (
            <>
              <button 
                className={`hamburger-menu ${isMobileMenuOpen ? 'open' : ''}`}
                onClick={toggleMobileMenu}
                aria-label="Toggle menu"
              >
                <span></span>
                <span></span>
                <span></span>
              </button>

              <nav className={`header-nav ${isMobileMenuOpen ? 'mobile-open' : ''}`}>
                <div className="header-nav-background" />
                <ul>
                  {filteredNavItems.map(item => (
                    <li key={item.id}>
                      <Link 
                        to={item.href}
                        className={location.pathname === item.href ? 'active' : ''}
                        onClick={handleNavClick}
                      >
                        {item.label}
                      </Link>
                    </li>
                  ))}
                  <li className="mobile-only">
                    <button 
                      className="header-login-button mobile-logout"
                      onClick={handleLogout}
                    >
                      <span className="header-button-content">
                        <span>Abmelden</span>
                        <svg 
                          width="20" 
                          height="20" 
                          viewBox="0 0 24 24" 
                          fill="none" 
                          stroke="currentColor" 
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"/>
                          <polyline points="16 17 21 12 16 7"/>
                          <line x1="21" y1="12" x2="9" y2="12"/>
                        </svg>
                      </span>
                    </button>
                  </li>
                </ul>
              </nav>
              <div className="header-right desktop-only">
                <button 
                  className="header-login-button"
                  onClick={handleLogout}
                >
                  <span className="header-button-glow"></span>
                  <span className="header-button-content">
                    <span>Abmelden</span>
                    <svg 
                      width="20" 
                      height="20" 
                      viewBox="0 0 24 24" 
                      fill="none" 
                      stroke="currentColor" 
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"/>
                      <polyline points="16 17 21 12 16 7"/>
                      <line x1="21" y1="12" x2="9" y2="12"/>
                    </svg>
                  </span>
                </button>
              </div>
            </>
          )}
          
          {!isAuthenticated && (
            <div className="header-right">
              <button 
                className="header-login-button"
                onClick={() => setIsLoginModalOpen(true)}
              >
                <span className="header-button-glow"></span>
                <span className="header-button-content">
                  <span>Anmelden</span>
                  <svg 
                    width="20" 
                    height="20" 
                    viewBox="0 0 24 24" 
                    fill="none" 
                    stroke="currentColor" 
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M9 18l6-6-6-6"/>
                  </svg>
                </span>
              </button>
            </div>
          )}
        </div>
      </header>

      <LoginModal 
        isOpen={isLoginModalOpen}
        onClose={() => setIsLoginModalOpen(false)}
      />
    </>
  );
};

export default Header;