import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import './Countdown.css';
import { API_BASE_URL } from '../../config/api';

const Countdown = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const [shuffledSponsors, setShuffledSponsors] = useState([]);
  const [countdown, setCountdown] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

  const convertToBool = (value) => {
    if (typeof value === 'boolean') return value;
    if (typeof value === 'string') {
      return value === '1';
    }
    return !!value;
  };

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/event/get-by-id.php?id=${eventId}`);
        if (!response.ok) throw new Error('Event nicht gefunden');
        const data = await response.json();
        if (!convertToBool(data.event.show_event)) {
          navigate('/');
          return;
        }
        setEvent({
          ...data.event,
          show_fights: convertToBool(data.event.show_fights),
          show_event: convertToBool(data.event.show_event)
        });

        // Get non-main sponsors and shuffle them once
        const otherSponsors = data.event.sponsors?.filter(sponsor => !sponsor.mainSponsor) || [];
        const shuffled = [...otherSponsors].sort(() => Math.random() - 0.5);
        setShuffledSponsors(shuffled);
      } catch (error) {
        navigate('/');
      }
    };
    fetchEvent();
  }, [eventId, navigate]);

  useEffect(() => {
    if (!event) return;

    const calculateCountdown = () => {
      const eventDate = new Date(event.date).getTime();
      const now = new Date().getTime();
      const difference = eventDate - now;

      if (difference <= 0) {
        setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        return;
      }

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      setCountdown({ days, hours, minutes, seconds });
    };

    calculateCountdown();
    const timer = setInterval(calculateCountdown, 1000);
    return () => clearInterval(timer);
  }, [event]);

  if (!event) return null;

  const mainSponsor = event.sponsors?.find(sponsor => sponsor.mainSponsor);

  return (
    <div className="countdown-container">
      <div className="countdown-hero" style={{
        backgroundImage: event.image ? 
          `linear-gradient(180deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8)), url(${API_BASE_URL}/uploads/${event.image})` :
          'linear-gradient(135deg, var(--color-primary), var(--color-primary-dark))'
      }}>
        <div className="countdown-hero-glass">
          <h1 className="countdown-title">{event.name}</h1>
          {event.description && <p className="countdown-description">{event.description}</p>}
          {event.show_fights && (
            <div className="fights-link-wrapper">
              <Link to={`/event/${eventId}/fights`} className="fights-link">
                <span className="fights-link-content">
                  <span className="fights-link-icon">🥊</span>
                  <span className="fights-link-text">Kämpfe ansehen</span>
                  <svg className="fights-link-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M3 10a.75.75 0 01.75-.75h10.638L10.23 5.29a.75.75 0 111.04-1.08l5.5 5.25a.75.75 0 010 1.08l-5.5 5.25a.75.75 0 11-1.04-1.08l4.158-3.96H3.75A.75.75 0 013 10z" clipRule="evenodd" />
                  </svg>
                </span>
              </Link>
            </div>
          )}
        </div>
      </div>

      <div className="countdown-content">
        <div className="countdown-timer">
          {Object.entries(countdown).map(([unit, value]) => (
            <div key={unit} className="countdown-time-block">
              <span className="countdown-number">{String(value).padStart(2, '0')}</span>
              <span className="countdown-label">
                {unit === 'days' ? 'Tage' :
                 unit === 'hours' ? 'Stunden' :
                 unit === 'minutes' ? 'Minuten' : 'Sekunden'}
              </span>
            </div>
          ))}
        </div>

        <div className="countdown-info">
          <div className="countdown-date">
            {new Date(event.date).toLocaleDateString('de-DE', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit'
            })}
          </div>
          <a href={`mailto:${event.organizer_email}`} className="countdown-contact">
            Kontakt: {event.organizer_email}
          </a>
        </div>

        {event.sponsors && event.sponsors.length > 0 && (
          <div className="countdown-sponsors">
            {mainSponsor && (
              <div className="countdown-main-sponsor">
                <h2 className="countdown-sponsor-heading">Präsentiert von</h2>
                <a 
                  href={mainSponsor.website_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="countdown-main-sponsor-link"
                >
                  <img 
                    src={`${API_BASE_URL}/uploads/${mainSponsor.image_url}`}
                    alt="Main Sponsor"
                    className="countdown-main-sponsor-image"
                  />
                </a>
              </div>
            )}

            {shuffledSponsors.length > 0 && (
              <div className="sponsors-grid">
                <h2 className="sponsors-title">Partner & Sponsoren</h2>
                <div className="sponsors-container">
                  {shuffledSponsors.map((sponsor, index) => (
                    <a 
                      key={sponsor.id || index}
                      href={sponsor.website_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="sponsor-card"
                    >
                      <div className="sponsor-image-wrapper">
                        <img 
                          src={`${API_BASE_URL}/uploads/${sponsor.image_url}`}
                          alt={`Sponsor ${index + 1}`}
                          className="sponsor-image"
                        />
                      </div>
                    </a>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Countdown;