import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import './PauseSettings.css';
import FightSponsor from '../FightSponsor/FightSponsor';

const PauseSettings = ({ isOpen, onClose, onSave, initialData, eventSponsors }) => {
  const [pauseData, setPauseData] = useState({
    duration: 60,
    position: 0,
    event_id: null,
    event_sponsor_index: null
  });

  useEffect(() => {
    if (initialData) {
      setPauseData({
        duration: initialData.duration || 60,
        position: initialData.position || 0,
        event_id: initialData.event_id || null,
        event_sponsor_index: initialData.event_sponsor_index !== undefined ? 
          initialData.event_sponsor_index : null
      });
    }
  }, [initialData]);

  const handleDurationChange = (minutes) => {
    const seconds = Math.max(0, parseInt(minutes) || 0) * 60;
    setPauseData(prev => ({
      ...prev,
      duration: seconds
    }));
  };

  const handleSponsorSelect = (index) => {
    setPauseData(prev => ({
      ...prev,
      event_sponsor_index: prev.event_sponsor_index === index ? null : index
    }));
  };

  const formatDurationHint = (seconds) => {
    return `${seconds} Sekunden`;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(pauseData);
  };

  if (!isOpen) return null;

  return (
    <div className="pause-settings-overlay">
      <div className="pause-settings-modal">
        <div className="pause-settings-header">
          <h2>{initialData ? 'Pause bearbeiten' : 'Neue Pause'}</h2>
          <button className="pause-settings-close" onClick={onClose}>
            <X size={24} />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="pause-settings-content">
          <div className="pause-settings-general">
            <h3>Pause Einstellungen</h3>
            <div className="pause-settings-form-group">
              <label>Dauer (Minuten)</label>
              <input
                type="number"
                value={Math.floor(pauseData.duration / 60)}
                onChange={(e) => handleDurationChange(e.target.value)}
                required
                min="1"
                step="1"
              />
              {pauseData.duration > 0 && (
                <span className="pause-settings-duration-hint">
                  {formatDurationHint(pauseData.duration)}
                </span>
              )}
            </div>

            {Array.isArray(eventSponsors) && eventSponsors.length > 0 && (
              <div className="fightsponsor-section">
                <h3 className="fightsponsor-title">Sponsor auswählen</h3>
                <div className="fightsponsor-grid">
                  {eventSponsors.map((sponsor, index) => (
                    <FightSponsor
                      key={index}
                      sponsor={sponsor}
                      index={index}
                      isSelected={pauseData.event_sponsor_index === index}
                      onClick={handleSponsorSelect}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>

          <div className="pause-settings-actions">
            <button type="button" className="pause-settings-cancel" onClick={onClose}>
              Abbrechen
            </button>
            <button type="submit" className="pause-settings-save">
              {initialData ? 'Speichern' : 'Pause erstellen'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PauseSettings;